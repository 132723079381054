//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-bulk-edit-date")
@Component({
    selector: "s25-ng-bulk-edit-date",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            <label class="rose-patternPicker-label c-margin-top--half"
                >Date:
                <s25-ng-editable-date
                    [(val)]="this.today.date"
                    (valChange)="onChangeDate($event)"
                ></s25-ng-editable-date>
            </label>

            <div class="c-margin-left--half c-margin-bottom--half c-margin-top--single">
                <s25-ng-bulk-edit-save [submitFn]="updateFn" [buttonText]="'Update'"> </s25-ng-bulk-edit-save>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditDateComponent implements OnInit {
    @Input() chosenModels?: any = [];
    @Input() itemTypeId: any;
    @Input() itemIds: any = undefined;
    @Input() itemType: string; //see S25BulkMap for options
    init: boolean;
    isValidDate: any = true;
    today: any = {
        date: S25Util.date.toEndOfDay(S25Util.date.createDate(new Date().getFullYear(), new Date().getMonth() + 1, 1)),
        showToday: false,
    };
    modelBean: any;
    selectDate: any;
    todoItemIds: any = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];

        if (parseInt(this.itemTypeId) === 10) {
            //todo
            this.todoItemIds = S25Util.deepCopy(this.itemIds);
            this.todoItemIds = this.todoItemIds.filter(function (x: any) {
                return x.indexOf("todo") !== -1;
            });
            this.todoItemIds = this.todoItemIds.map((x: any) => x.replace("todo", ""));
            // other, such as ids as task or task_multi_
            this.itemIds = this.itemIds.filter(function (x: any) {
                return x.indexOf("todo") === -1;
            });
            this.itemIds = await S25BulkEditUtil.getMulitTaskIds(this.itemIds, true);
        }

        S25LoadingApi.destroy(this.elementRef.nativeElement);

        this.init = true;
        this.cd.detectChanges();
    }

    /*
    ngOnInit(): void {
          this.init = true;   
          this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];   
          parseInt(this.itemTypeId) === 10 ?  this.itemIds = this.itemIds.map((x : any)  => x.replace('task_multi_',"")) : '';       
          parseInt(this.itemTypeId) === 10 ?  this.itemIds = this.itemIds.map((x : any)  => x.replace('task',"")) : '';  
          this.itemIds = this.itemIds.filter(function (x: any ) { return x.indexOf('todo') === -1; });    
          this.cd.detectChanges();
    } */

    onChangeDate(e: any) {
        this.selectDate = e;
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let payload: any = [];
        /*
          payload= {
               taskIdList: this.itemIds,
               rsvpDate : S25Datefilter.transform(this.today.date, "yyyy-MM-dd") + 'T00:00:00'  //2019-10-28T00:00:00
          } */
        if (this.itemIds && this.itemIds.length > 0) {
            payload.push({
                taskIdList: this.itemIds,
                rsvpDate: S25Datefilter.transform(this.today.date, "yyyy-MM-dd") + "T00:00:00", //2019-10-28T00:00:00
            });
        }

        if (this.todoItemIds && this.todoItemIds.length > 0) {
            payload.push({
                todoIdList: this.todoItemIds,
                rsvpDate: S25Datefilter.transform(this.today.date, "yyyy-MM-dd") + "T00:00:00", //2019-10-28T00:00:00
            });
        }

        return this.modelBean.service(payload);
    }
}
