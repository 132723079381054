import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { EventService } from "../../../services/event.service";
import { UserprefService } from "../../../services/userpref.service";
import { Event } from "../../../pojo/Event";
import { EventStateConst } from "../../../services/event.state.change.service";
import { PreferenceService } from "../../../services/preference.service";

@TypeManagerDecorator("s25-ng-bulk-edit-state")
@Component({
    selector: "s25-ng-bulk-edit-state",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            <s25-loading-inline data-model="{}"></s25-loading-inline>

            <select [(ngModel)]="chosenStateId" class="c-selectInput" aria-label="Select State">
                <option *ngFor="let state of statesList" [ngValue]="state.id">
                    {{ state.name }}
                </option>
            </select>

            <button
                class="aw-button aw-button--danger--outline ngBlock c-margin-bottom--single c-margin-top--single"
                [disabled]="isLoading"
                (click)="runAction()"
            >
                Update Event State
            </button>

            <div
                *ngIf="successMsg"
                class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
                role="alert"
            >
                <div class="cn-alert__icon cn-icon" name="alert--info">
                    <svg class="cn-svg-icon" role="img">
                        <title>Success Alert</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                        ></use>
                    </svg>
                </div>
                <div class="cn-alert__label">
                    <span>{{ successMsg }}</span>
                </div>
            </div>

            <s25-ng-bulk-edit-issues-list
                *ngIf="noPermEvents && noPermEvents.length > 0"
                [title]="'No Permissions List'"
                [items]="noPermEvents"
            ></s25-ng-bulk-edit-issues-list>

            <s25-ng-bulk-edit-issues-list
                *ngIf="lockedEvents && lockedEvents.length > 0"
                [title]="'Locked Events List'"
                [items]="lockedEvents"
            ></s25-ng-bulk-edit-issues-list>

            <s25-ng-bulk-edit-issues-list
                *ngIf="multiRouteEvents && multiRouteEvents.length > 0"
                [title]="'Drafts With Multiple Folders List'"
                [items]="multiRouteEvents"
            ></s25-ng-bulk-edit-issues-list>

            <s25-ng-bulk-edit-issues-list
                *ngIf="erroredEvents && erroredEvents.length > 0"
                [title]="'Errors'"
                [items]="erroredEvents"
            ></s25-ng-bulk-edit-issues-list>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditStateComponent implements OnInit {
    @Input() itemTypeId?: number = undefined;
    @Input() itemType?: string = undefined;
    @Input() itemIds?: number[] = [];

    init: boolean;
    itemName: string;
    modelBean: any;
    isLoading: false;
    successMsg: string;

    chunkSize = 50;
    concurrentMax = 5;
    arrIndex = 0;
    chunkCount = 0;
    chunkIdx = 0;

    noPermEvents: S25ItemI[] = [];
    lockedEvents: S25ItemI[] = [];
    erroredEvents: S25ItemI[] = [];
    multiRouteEvents: S25ItemI[] = [];

    statesList: Event.State.Data[];
    chosenState: Event.State.Data;
    chosenStateId: Event.State.Id;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        const { preferences, allowedStates } = await this.getData();
        this.chosenStateId = parseInt(preferences?.SpbkEvState?.value ?? "1");
        this.statesList = this.mappedStates(allowedStates);
        this.chunkCount = Math.ceil(this.itemIds.length / this.chunkSize);
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.init = true;
        this.cd.detectChanges();
    }

    runAction() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        let batchHandler: any = (chunkIdx: any) => {
            this.chunkIdx++;
            let start = chunkIdx * this.chunkSize; //0,         chunkSize,     2 * chunkSize...
            let end = start + this.chunkSize; //     chunkSize, 2 * chunkSize, 3 * chunkSize...
            //example: chunkCount: 3, chunkSize: 50, events: 147
            //first chunk: 0 to chunkSize - 1 (the minus 1 is bc slice goes until end - 1)
            // => 0 to 49
            //last  chunk: (this.chunkCount - 1) * this.chunkSize to  (this.chunkCount - 1) * this.chunkSize + this.chunkSize
            // => 2 * 50 to 2 * 50 + 50 => 100 to 150
            let chunk = this.itemIds.slice(start, end);
            return S25Util.all([EventService.getEtags(chunk), this.modelBean]).then((resp) => {
                return resp[1].service(chunk, this.chosenStateId, resp[0]);
            });
        };

        S25Util.batch(batchHandler, this.concurrentMax, this.chunkCount).then(
            (resp) => {
                const perms = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "noPerm") || [];
                const locked = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "locked") || [];
                const error = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "errors") || [];
                const noRoute = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "info") || [];

                this.noPermEvents = this.noPermEvents.concat(perms);
                this.lockedEvents = this.lockedEvents.concat(locked);
                this.erroredEvents = this.erroredEvents.concat(error);
                this.multiRouteEvents = this.multiRouteEvents.concat(noRoute);

                error.length === 0 ? (this.successMsg = "Success!") : "";

                this.cd.detectChanges();
                this.done();
            },
            (error) => {
                this.done(error);
            },
        );
    }

    done(error?: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.isLoading = false;
        error && S25Util.showError(error);
    }

    getData() {
        return Promise.all([
            PreferenceService.getPreferences(["config_BPE_event_get", "SpbkEvState"]),
            UserprefService.getAllowedStates(),
        ]).then(([preferences, allowedStates]) => {
            return { preferences, allowedStates };
        });
    }

    mappedStates(allowedStates: Event.State.Id[]) {
        return allowedStates.map((id) => {
            return {
                id: id,
                name: EventStateConst.stateMap[id],
            } as Event.State.Data;
        });
    }
}
