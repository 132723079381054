//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { EventService, InheritType } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { AlertService } from "../../../services/alert.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-bulk-edit-inherit")
@Component({
    selector: "s25-ng-bulk-edit-inherit",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            {{ displayText }}
            <div *ngFor="let i of this.chosenModels">
                <span class="c-margin-left--half">{{ i.itemName }}</span>
            </div>
            <s25-ng-bulk-edit-save [submitFn]="saveFn" [buttonText]="buttonText"></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditInheritComponent implements OnInit {
    @Input() chosenModels?: any = [];
    @Input() itemIds: number[];
    @Input() itemTypeId: number = 2;
    @Input() itemType: string; //see S25BulkMap for options
    init: boolean;
    buttonText: string;
    displayText: string;
    confirmText: string;
    succeseMsg: string;
    inheritType: InheritType;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        if (this.itemTypeId === 1) {
            this.displayText = "Please confirm that you want to inherit data to these events:";
            this.buttonText = "Inherit data to event";
            this.confirmText = "Are you sure you want to inherit folder data to these events?";
            this.succeseMsg = "Folder to event inheritance has completed.";
            this.inheritType = "event";
        } else {
            this.displayText = "Please confirm that you want to inherit data from this folder's parent:";
            this.buttonText = "Inherit data to folder";
            this.confirmText = "Are you sure want to Inherit data to folder's parent?";
            this.succeseMsg = "Inherit to folder complete.";
            this.inheritType = "folder";
        }
        this.init = true;
        this.cd.detectChanges();
    }

    get saveFn() {
        return this.save.bind(this);
    }

    save() {
        return AlertService.confirm(this.confirmText).then((answer) => {
            if (answer) {
                let ids = this.itemIds.join("+");
                return EventService.postEventInherit(ids, this.inheritType).then((resp) => {
                    if (resp && resp.results && resp.results.result) {
                        this.getResults(resp.results.result.key);
                    }
                });
            }
        });
    }

    getResults(key: any) {
        return EventService.getResults(key).then((resp) => {
            //let find = S25Util.array.forceArray(resp);
            if (
                (resp && resp.results && resp.results.result && resp.results.result.key) ||
                (resp && resp.result && resp.result.key)
            ) {
                S25LoadingApi.init(this.elementRef.nativeElement);
                this.getResults(key);
            } else {
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                alert(this.succeseMsg);
            }
        });
    }
}
