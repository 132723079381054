//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { jSith } from "../../../util/jquery-replacement";
import { PayloadI } from "../../../pojo/EventTypeBulkEditPayloadI";

@TypeManagerDecorator("s25-ng-bulk-edit-event-type-reports")
@Component({
    selector: "s25-ng-bulk-edit-event-type-reports",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        <div *ngIf="this.init">
            <div class="c-margin-top--half c-margin-bottom--half">
                <div class="ngBold c-margin-top--half">Select {{ this.confModelBean.title }}</div>
                <s25-ng-dropdown-search-criteria
                    [type]="this.confModelBean.searchCriteriaName"
                    [staticItems]="this.staticItems"
                    [(chosen)]="this.chosenConf"
                    (chosenChange)="onChosenConf()"
                ></s25-ng-dropdown-search-criteria>
                <button
                    *ngIf="this.chosenConf && this.chosenConf.itemId"
                    class="aw-button aw-button--danger--transparent elt--primaryOrg-margin-top"
                    (click)="removeConfInv('conf')"
                >
                    Remove
                </button>
            </div>

            <div class="c-margin-top--half c-margin-bottom--half">
                <div class="ngBold c-margin-top--half">Select {{ this.invModelBean.title }}</div>
                <s25-ng-dropdown-search-criteria
                    [type]="this.invModelBean.searchCriteriaName"
                    [staticItems]="this.staticItems"
                    [(chosen)]="this.chosenInv"
                    (chosenChange)="onChosenInv()"
                ></s25-ng-dropdown-search-criteria>
                <button
                    *ngIf="this.chosenInv && this.chosenInv.itemId"
                    class="aw-button aw-button--danger--transparent elt--primaryOrg-margin-top"
                    (click)="removeConfInv('inv')"
                >
                    Remove
                </button>
            </div>

            <span class="c-margin-bottom--single c-margin-right--half ngInlineBlock ngBold"
                >Add/Remove {{ this.title }}</span
            >
            <s25-toggle-button
                (modelValueChange)="this.onToggleChange($event)"
                [modelValue]="this.remove"
                [trueLabel]="trueLabel"
                [falseLabel]="falseLabel"
            ></s25-toggle-button>

            <div *ngIf="this.modelBeanInit">
                <s25-ng-multiselect-search-criteria
                    [selectedItems]="this.selectedItems"
                    [(modelBean)]="this.modelBean"
                    honorMatching="true"
                    [type]="this.type"
                    [customFilterValue]="customFilterValue"
                ></s25-ng-multiselect-search-criteria>
            </div>

            <s25-ng-bulk-edit-save
                [submitFn]="updateFn"
                [buttonText]="this.saveButtonText"
                [buttonDisabled]="this.buttonDisabled"
            ></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditEventTypeReportsComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    @Input() fields?: string[] = ["quantity", "comments"]; //Used to hide fields
    @Input() idsOnly: boolean = false; // Event cat pass ids instead of the object, so make this flexible either just ids or the object
    @Input() falseLabel: string = "Add";
    @Input() trueLabel: string = "Remove";
    modelBean: any = { showResult: true, showMatching: false };
    init: boolean = false;
    type: any;
    remove: boolean = false;
    mappingModelBean: any;
    title: any;
    saveButtonText: string;
    customFilterValue: string = "";
    confModelBean: any;
    invModelBean: any;
    chosenConf: any = [];
    chosenInv: any = [];
    selectedItems: any = []; //[{itemId: -2, itemName: 'Scheduler', isPermanent: true}, {itemId: -1, itemName: 'Requestor', isPermanent: true}];
    modelBeanInit: boolean = false;
    modelBeanInitAddItems: any;

    staticItems = [
        {
            dropDownOrigName: "No Default",
            isSelected: true,
            itemId: 0,
            itemName: "No Default",
            noAnchorClick: true,
            txt: "No Default",
            val: "",
        },
    ];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.confModelBean = S25BulkMap[this.itemTypeId]["reportConfirm"];
        this.invModelBean = S25BulkMap[this.itemTypeId]["reportInvoice"];
        this.type = this.mappingModelBean.searchCriteriaType;
        this.modelBean.hasQuantity = this.mappingModelBean.hasQuantity;
        this.title = this.mappingModelBean.title;
        this.saveButtonText = "Add " + this.mappingModelBean.title;
        this.init = true;
        this.modelBeanInit = true;
        this.cd.detectChanges();

        // call search criteria data
        this.modelBean.dataPromise &&
            this.modelBean.dataPromise.then(() => {
                S25BulkEditUtil.done(this.elementRef.nativeElement);
                this.cd.detectChanges();
            });
    }

    onChosenConf() {
        this.chosenConf.isPermanent = true;
        this.chosenConf.itemId !== 0 ? this.reSetSelectItems() : "";
    }

    onChosenInv() {
        this.chosenInv.isPermanent = true;
        this.chosenInv.itemId !== 0 ? this.reSetSelectItems() : "";
    }

    reSetSelectItems() {
        if (!this.remove) {
            let modelBean = this.modelBean;
            this.selectedItems = [];
            this.modelBeanInit = false;
            this.cd.detectChanges();

            if (
                this.chosenConf.length !== 0 &&
                this.chosenInv.length !== 0 &&
                this.chosenInv.itemId === this.chosenConf.itemId
            ) {
                this.selectedItems.push(this.chosenConf);
            } else {
                this.chosenConf.length !== 0 ? this.selectedItems.push(this.chosenConf) : "";
                this.chosenInv.length !== 0 ? this.selectedItems.push(this.chosenInv) : "";
            }

            jSith.forEach(modelBean.addedItems, (key, i) => {
                if (i.itemId !== this.chosenInv.itemId && i.itemId !== this.chosenConf.itemId) {
                    this.selectedItems.push({
                        itemName: i.itemName,
                        itemId: i.itemId,
                        checked: true,
                        isPermanent: false,
                    });
                }
            });

            this.selectedItems = S25Util.array.unique(this.selectedItems);
            this.modelBeanInit = true;
            this.cd.detectChanges();
        }
    }

    onToggleChange(event: any) {
        this.remove = event;
        let removeButtonText = "Remove " + this.mappingModelBean.title;
        let addButtonText = "Add " + this.mappingModelBean.title;
        this.saveButtonText = this.remove ? removeButtonText : addButtonText;
        this.cd.detectChanges();
    }

    removeConfInv(e: any) {
        e === "conf" ? (this.chosenConf = []) : (this.chosenInv = []);
        this.reSetSelectItems();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let addItemIds: any = [];
        let removeItemIds: any = [];
        this.remove ? (removeItemIds = this.modelBean.addedItems) : (addItemIds = this.modelBean.selectedItems);
        if (this.idsOnly) {
            addItemIds = S25Util.toItemIds(addItemIds);
            removeItemIds = S25Util.toItemIds(removeItemIds);
        }
        let reportSelectedItems: any = [];
        let selectedItems: any = {};
        let payload: PayloadI = undefined;

        selectedItems.ancillary = {};

        if (this.remove) {
            let ancillary = {};
            jSith.forEach(removeItemIds, (key, i) => {
                if (i.itemId !== this.chosenInv.itemId && i.itemId !== this.chosenConf.itemId) {
                    reportSelectedItems.push({ reportId: i.itemId });
                }
            });
            this.chosenConf.length !== 0
                ? this.chosenConf.itemId === 0
                    ? (selectedItems.confirmation = "")
                    : (selectedItems.confirmation = { reportId: this.chosenConf.itemId })
                : ""; //add confirmation
            this.chosenInv.length !== 0
                ? this.chosenInv.itemId === 0
                    ? (selectedItems.invoice = "")
                    : (selectedItems.invoice = { reportId: this.chosenInv.itemId })
                : "";
            reportSelectedItems.length !== 0 ? (ancillary = reportSelectedItems) : ""; // add ancillary
            payload = { idList: this.itemIds, reports: selectedItems, remove: { reports: ancillary } };
        } else {
            jSith.forEach(addItemIds, (key, i) => {
                reportSelectedItems.push({ reportId: i.itemId });
            });
            this.chosenConf.length !== 0
                ? this.chosenConf.itemId === 0
                    ? (selectedItems.confirmation = "")
                    : (selectedItems.confirmation = { reportId: this.chosenConf.itemId })
                : ""; //add confirmation
            this.chosenInv.length !== 0
                ? this.chosenInv.itemId === 0
                    ? (selectedItems.invoice = "")
                    : (selectedItems.invoice = { reportId: this.chosenInv.itemId })
                : ""; //add invoice
            reportSelectedItems.length !== 0 ? (selectedItems.ancillary = reportSelectedItems) : ""; // add ancillary
            payload = { idList: this.itemIds, reports: selectedItems };
        }
        return this.mappingModelBean.service(payload);
    } //END update
}
