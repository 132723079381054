//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-comment-instr")
@Component({
    selector: "s25-ng-bulk-edit-comment-instr",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            <label for="ngTextArea-1" class="ngBold">
                {{ this.title }}
            </label>

            <div *ngIf="!this.textArea">
                <s25-ng-rich-text-editor
                    [modelValue]="this.desc"
                    autoFocus="false"
                    (onEditorContentChange)="this.onChange($event)"
                ></s25-ng-rich-text-editor>
            </div>

            <div *ngIf="this.textArea">
                <textArea rows="4" cols="20" (change)="this.onChange($event.target.value)"> </textArea>
            </div>

            <s25-ng-bulk-edit-save [submitFn]="updateFn" [buttonText]="'Update ' + this.title"></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditCommenttInstrComponent implements OnInit {
    @Input() itemTypeId: any;
    @Input() itemIds: any = undefined;
    @Input() type: string = "comment";
    @Input() textArea: boolean = false;
    desc: string;
    init: boolean;
    modelBean: any;
    title: any;
    todoItemIds: any = [];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();
        this.modelBean = S25BulkMap[this.itemTypeId][this.type];

        if (parseInt(this.itemTypeId) === 10) {
            //todo
            this.todoItemIds = S25Util.deepCopy(this.itemIds);
            this.todoItemIds = this.todoItemIds.filter(function (x: any) {
                return x.indexOf("todo") !== -1;
            });
            this.todoItemIds = this.todoItemIds.map((x: any) => x.replace("todo", ""));
            // other, such as ids as task or task_multi_
            this.itemIds = this.itemIds.filter(function (x: any) {
                return x.indexOf("todo") === -1;
            });
            this.itemIds = await S25BulkEditUtil.getMulitTaskIds(this.itemIds, true);
        }
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.title = this.modelBean.title;
        this.init = true;
        this.cd.detectChanges();
    }

    /*
    
    ngOnInit() {
        this.modelBean = S25BulkMap[this.itemTypeId][this.type];
        parseInt(this.itemTypeId) === 10 ?  this.itemIds = this.itemIds.map((x : any)  => x.replace('task_multi_',"")) : '';   
        parseInt(this.itemTypeId) === 10 ?  this.itemIds = this.itemIds.map((x : any)  => x.replace('task',"")) : '';
        this.itemIds = this.itemIds.filter(function (x: any ) { return x.indexOf('todo') === -1; });
        this.title = this.modelBean.title;
        this.init = true;
        this.cd.detectChanges();       
    }

    */

    onChange(e: any) {
        this.desc = e;
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (parseInt(this.itemTypeId) === 10) {
            // 10 = task
            let payload: any = [];
            if (this.itemIds && this.itemIds.length > 0) {
                payload.push({
                    taskIdList: this.itemIds,
                    comments: this.desc,
                });
            }

            if (this.todoItemIds && this.todoItemIds.length > 0) {
                payload.push({
                    todoIdList: this.todoItemIds,
                    comments: this.desc,
                });
            }
            /*
               let payload : any=  {};
               payload= {
                    taskIdList: this.itemIds,
                    comments : this.desc
               } */
            return this.modelBean.service(payload);
        } else {
            return this.modelBean.service(this.itemIds, S25BulkMap[this.itemTypeId][this.type].type, this.desc);
        }
    }
}
