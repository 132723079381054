//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { EventService } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { PayloadI } from "../../../pojo/EventTypeBulkEditPayloadI";

@TypeManagerDecorator("s25-ng-bulk-edit-date-range")
@Component({
    selector: "s25-ng-bulk-edit-date-range",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            <div *ngIf="!this.isSameParent" class="ngBlod">
                Because your selected folders have different parents, you cannot edit the Date Range.
            </div>

            <div *ngIf="this.isSameParent">
                <div class="ngBold c-margin-top--half">Date Range</div>

                <label class="rose-patternPicker-label c-margin-top--half"
                    >Start Date:
                    <s25-ng-editable-date
                        [(val)]="this.startDate.date"
                        (valChange)="onChangeDate($event, 'start')"
                    ></s25-ng-editable-date>
                </label>
                <label class="rose-patternPicker-label c-margin-top--half"
                    >End Date:
                    <s25-ng-editable-date
                        [(val)]="this.endDate.date"
                        (valChange)="onChangeDate($event, 'end')"
                    ></s25-ng-editable-date>
                </label>

                <div class="ngBold ngRed" *ngIf="this.isValidDate !== true">{{ this.isValidDate }}</div>

                <div class="ngBold ngRed" *ngIf="!this.validateStartDate || !this.validateEndDate">
                    Date must be inside the cabinet/folder dates between {{ this.parentStartDate }} and
                    {{ this.parentEndDate }}.
                </div>

                <div class="c-margin-left--half c-margin-bottom--half c-margin-top--half">
                    <s25-ng-bulk-edit-save
                        [submitFn]="saveFn"
                        [buttonText]="'Update Date Range'"
                        [buttonDisabled]="this.isValidDate !== true || !this.validateStartDate || !this.validateEndDate"
                    ></s25-ng-bulk-edit-save>
                </div>
            </div>

            <div
                *ngIf="this.successList.length > 0"
                class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
                role="alert"
            >
                <div class="cn-alert__icon cn-icon" name="alert--info">
                    <svg class="cn-svg-icon" role="img">
                        <title>Success Alert</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                        ></use>
                    </svg>
                </div>
                <div class="cn-alert__label">
                    <div>Success! Updated:</div>
                    <div *ngFor="let i of this.successList">
                        {{ i.itemName }}
                    </div>
                </div>
            </div>

            <div
                *ngIf="this.errorList.length > 0"
                class=" ngBold cn-alert cn-alert--warning c-margin-bottom--single"
                role="alert"
            >
                <div class="cn-alert__icon cn-icon" name="alert--info">
                    <div>Could not update cabinet(s).</div>
                    <div *ngFor="let i of this.errorList">{{ i.itemName }}: {{ i.error[0].message }}</div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditDateRangeComponent implements OnInit {
    @Input() chosenModels?: any = [];
    @Input() itemIds: number[];
    @Input() itemTypeId: number = 10; // 10 = cabinet, 11 = folder see ItemActionsMap
    @Input() itemType: string; //see S25BulkMap for options
    init: boolean;
    isValidDate: any = true;
    endDate: any = {
        date: S25Util.date.toEndOfDay(
            S25Util.date.createDate(new Date().getFullYear() + 1, new Date().getMonth() + 1, 1),
        ),
        showToday: false,
    };
    startDate: any = {
        date: S25Util.date.toStartOfDay(
            S25Util.date.createDate(new Date().getFullYear(), new Date().getMonth() + 1, 1),
        ),
        showToday: false,
    };
    isSameParent: boolean = true;
    parentStartDate: any;
    parentEndDate: any;
    validateStartDate: any = true;
    validateEndDate: any = true;
    successMsg: any;
    updatedList: any = [];
    errorList: any = [];
    successList: any = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.init = true;
        // if folder  first check selected items have same parent or not, if not, not allow to do bulk edit
        if (this.itemTypeId === 11) {
            let getParentList: any[] = this.chosenModels.map((obj: any) => {
                return obj.itemParentId;
            });
            getParentList = S25Util.array.unique(getParentList);
            if (getParentList && getParentList.length > 1) {
                this.isSameParent = false;
            } else {
                this.isSameParent = true;
                this.getParentInfoById(parseInt(getParentList[0])); //get parent's Date Range to validate, be sure in new date ranges inside parent's date range.
            }
        }

        this.cd.detectChanges();
    }

    get saveFn() {
        return this.save.bind(this);
    }

    save() {
        if (this.itemTypeId === 11) {
            this.validateEndDate = S25Util.date.isBetweenEqual(
                this.endDate.date,
                new Date(this.parentStartDate),
                new Date(this.parentEndDate),
            )
                ? true
                : false;
            this.cd.detectChanges();
        }

        if (this.validateEndDate) {
            let payload: PayloadI = undefined;

            payload = {
                idList: this.itemIds,
                dates: {
                    startDate: S25Datefilter.transform(this.startDate.date, "yyyy-MM-dd") + "T00:00:00",
                    endDate: S25Datefilter.transform(this.endDate.date, "yyyy-MM-dd") + "T23:59:00",
                },
            };

            return EventService.updateBulkEditContainer(payload).then((data) => {
                if (data) {
                    this.updatedList = S25Util.deepCopy(this.chosenModels);
                    let errorList: any = [];
                    let find: any = [];

                    if (data.content.errors) {
                        // this.errList = data.content.errors;
                        /*
                         let msg:any = data.content.errors[0].message;
                         msg = msg.split(".");
                         msg = msg[2].split(",");
                         msg = msg[1].replace(" earliest child start_dt: ", '');
                         msg = msg.replace(" latest child end_dt: ", '');

                         let date =  S25Datefilter.transform(new Date(msg), "yyyy-MM-dd");
                         let name = "cabinet(s)";
                         this.itemTypeId === 11? name ="folder" :'';
                         
                        if (data.content.errors[0].message.indexOf("Input start_dt") > -1){
                              alert("Could not update " + name +  ". Start date must be the same as or earlier than the earliest child folder's start date, " + date + " ."); 
                        }else{
                              alert("Could not update " + name +  ". End date must be equal to or after " + date + " ."); 
                        } 
                        */

                        // jSith.forEach(data.content.errors, (key, c) => {
                        //      this.updatedList = this.updatedList.filter((x : any) => {
                        //           return x.itemId !== c.objectId;
                        //      });

                        // });

                        this.updatedList = this.chosenModels.map((obj: any) => {
                            let c = data.content.errors.filter((c: any) => c.objectId === obj.itemId);
                            return { ...obj, error: c };
                        });
                        this.errorList = this.updatedList.filter((x: any) => {
                            return x.error.length > 0;
                        });
                        this.successList = this.updatedList.filter((x: any) => {
                            return !x.error.length;
                        });

                        if (this.errorList.length > 0) {
                            jSith.forEach(this.errorList, (key, c) => {
                                let errormsg: any = S25Util.deepCopy(c.error[0].message);
                                errormsg = errormsg.split(".");
                                errormsg = errormsg[2].split(",");
                                errormsg = errormsg[1].replace(" earliest child start_dt: ", "");
                                errormsg = errormsg.replace(" latest child end_dt: ", "");
                                errormsg = S25Datefilter.transform(new Date(errormsg), "yyyy-MM-dd");
                                //c.error[0].message = S25Datefilter.transform(new Date(errormsg), "yyyy-MM-dd");
                                if (c.error[0].message.indexOf("Input start_dt") > -1) {
                                    c.error[0].message = " Start date must be equal to or before " + errormsg + ".";
                                } else {
                                    c.error[0].message = " End date must be equal to or after " + errormsg + ".";
                                }
                                if (c.error[1]) {
                                    let errormsgEnd: any = c.error[1].message;
                                    errormsgEnd = errormsgEnd.split(".");
                                    errormsgEnd = errormsgEnd[2].split(",");
                                    errormsgEnd = errormsgEnd[1].replace(" earliest child start_dt: ", "");
                                    errormsgEnd = errormsgEnd.replace(" latest child end_dt: ", "");
                                    //c.error[1].message = S25Datefilter.transform(new Date(errormsgEnd), "yyyy-MM-dd");
                                    c.error[0].message +=
                                        "  End date must be equal to or after " +
                                        S25Datefilter.transform(new Date(errormsgEnd), "yyyy-MM-dd") +
                                        ".";
                                }
                            });
                        }
                    }

                    if (!data.content.errors) {
                        this.successList = this.updatedList;
                    }

                    this.cd.detectChanges();
                }
            });
        }
    }

    onChangeDate(e: any, type: any) {
        if (type === "start") {
            this.startDate.date = S25Datefilter.transform(e, "yyyy-MM-dd");
            //If Folder check new start date inside parent start date
            if (this.itemTypeId === 11) {
                this.validateStartDate = S25Util.date.isBetweenEqual(
                    this.startDate.date,
                    new Date(this.parentStartDate),
                    new Date(this.parentEndDate),
                )
                    ? true
                    : false;
            }
            this.isValidDate =
                new Date(this.startDate.date) < new Date(this.endDate.date)
                    ? true
                    : "The start Date must be before the end date.";
            this.cd.detectChanges();
        } else {
            //If Folder check new end date inside parent end date
            this.endDate.date = S25Datefilter.transform(e, "yyyy-MM-dd");
            if (this.itemTypeId === 11) {
                this.validateEndDate = S25Util.date.isBetweenEqual(
                    this.endDate.date,
                    new Date(this.parentStartDate),
                    new Date(this.parentEndDate),
                )
                    ? true
                    : false;
            }
            this.isValidDate =
                new Date(this.startDate.date) < new Date(this.endDate.date)
                    ? true
                    : "The start Date must be before the end date.";
            this.cd.detectChanges();
        }
    }

    getParentInfoById(id: any) {
        return EventService.getEventById(id).then((data) => {
            if (data) {
                this.parentStartDate = data.start_date;
                this.parentEndDate = data.end_date;
            }
        });
    }
}
