//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25ItemI } from "../../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-bulk-edit-delete")
@Component({
    selector: "s25-ng-bulk-edit-delete",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            <s25-delete
                [hasToggle]="true"
                [warningMsg]="this.message"
                [deleteFn]="deleteFn"
                [confirmMsg]="this.confirmMsg"
            ></s25-delete>
        </div>
        <div
            *ngIf="this.successMsg"
            class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single c-margin-top--single"
            role="alert"
        >
            <div class="cn-alert__icon cn-icon" name="alert--info">
                <svg class="cn-svg-icon" role="img">
                    <title>Success Alert</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                    ></use>
                </svg>
            </div>
            <div class="cn-alert__label">
                <span>{{ this.successMsg }}</span>
            </div>
        </div>
        <s25-ng-bulk-edit-issues-list
            *ngIf="issueList && issueList.length > 0"
            [title]="'No Permissions List'"
            [items]="issueList"
        ></s25-ng-bulk-edit-issues-list>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditDeleteComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType: any = undefined;
    @Input() itemIds: any = undefined;

    message: string;
    init: boolean;
    itemName: string;
    isConfirm: any;
    confirmMsg: any;

    isLoading: false;
    successMsg: any;

    chunkSize = 500; //50 ////////////////////////////////////// TO DO ///////////////////////////
    concurrentMax = 5;
    arrIndex = 0;
    chunkCount = 0;
    chunkIdx = 0;

    issueList: S25ItemI[];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.chunkCount = Math.ceil(this.itemIds.length / this.chunkSize);
        this.message = S25BulkMap[this.itemTypeId].message.warningMsg;
        this.itemName = S25BulkMap[this.itemTypeId].name.plural;
        this.confirmMsg = " Delete " + this.itemName + "?";
        this.init = true;
    }

    get deleteFn() {
        return this.delete.bind(this);
    }

    delete() {
        return this.runAction();
    }

    runAction() {
        let batchHandler: any = (chunkIdx: any) => {
            this.chunkIdx++;
            let start = chunkIdx * this.chunkSize; //0,         chunkSize,     2 * chunkSize...
            let end = start + this.chunkSize; //     chunkSize, 2 * chunkSize, 3 * chunkSize...
            //example: chunkCount: 3, chunkSize: 50, events: 147
            //first chunk: 0 to chunkSize - 1 (the minus 1 is bc slice goes until end - 1)
            // => 0 to 49
            //last  chunk: (this.chunkCount - 1) * this.chunkSize to  (this.chunkCount - 1) * this.chunkSize + this.chunkSize
            // => 2 * 50 to 2 * 50 + 50 => 100 to 150
            //console.log({start: start, end: end, slice: this.itemIds.slice(start, end)})
            let chunk = this.itemIds.slice(start, end);
            ////////////////////////////////////// TO DO ///////////////////////////
            let ids = chunk.join("+");
            // console.log({ids: ids});
            return S25BulkMap[this.itemTypeId].delete.service(ids);
            ///////////////////////////////
        };

        S25Util.batch(batchHandler, this.concurrentMax, this.chunkCount).then(
            (resp) => {
                let checkSuccess = resp.find((i: any) => i.success === false);
                !checkSuccess ? (this.successMsg = "Success!") : "";

                this.issueList = S25BulkEditUtil.parseNoPermsList(resp);
                this.cd.detectChanges();
                this.done();
            },
            (error) => {
                this.done(error);
            },
        );
    }

    done(error?: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.isLoading = false;
        error && S25Util.showError(error);
    }
}
